<template>
  <div class="flex h-dvh max-sm:flex-col">
    <header class="block lg:inset-y-0 lg:z-50 lg:flex-col xl:w-64">
      <div class="relative z-30 flex h-full grow flex-col justify-between gap-y-5 overflow-y-auto bg-primary px-3 py-5 max-sm:pt-12 sm:py-6 lg:px-4 lg:py-10 xl:px-6">
        <AppMenuMobile
          v-model:open="open"
          :menu="menu"
        />
        <img
          v-if="$route.name !== 'index'"
          class="absolute inset-x-0 bottom-0 -z-10 sm:hidden"
          src="~/assets/images/mobile-header-bg-trees.svg"
          alt=""
        >
        <div class="flex justify-between gap-4">
          <button
            type="button"
            class="text-white sm:hidden"
            @click="open = !open"
          >
            <UiIcon
              name="Hamburger"
              size="large"
            />
          </button>
          <div class="w-full">
            <NuxtLink :to="{name: 'index'}">
              <NuxtImg
                class="mx-auto block w-full max-w-36 sm:hidden lg:block"
                :src="logo"
                alt=""
              />
              <img
                class="mx-auto w-8 max-sm:hidden lg:hidden"
                src="~/assets/images/favicon.svg"
                alt=""
              >
            </NuxtLink>
            <AppMenu
              :menu="menu"
              class="max-sm:hidden"
            />
          </div>
          <NuxtLink
            :to="{name: 'profiel'}"
            class="flex items-center text-white sm:hidden"
            @click="open = !open"
          >
            <UiIcon
              name="User"
              size="small"
            />
          </NuxtLink>
        </div>
      </div>
    </header>

    <div class="flex-1 max-sm:overflow-y-auto">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
const {logo} = useApp();

const open = ref(false);

const menu = [
  {label: 'Dashboard', icon: 'Home', to: {name: 'index'}},
  {label: 'Offertes', icon: 'File', to: {name: 'offertes'}},
  {label: 'Boekingen', icon: 'Calendar', to: {name: 'boekingen'}},
  {label: 'Profiel', icon: 'User', to: {name: 'profiel'}},
];
</script>
